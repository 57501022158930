<template>
  <div class="edit-payment-terms-sidebar">
    <div class="edit-payment-terms-sidebar--content">
      <span style="display: inline-flex" class="text-gray margin-b-5">
        <div class="font-weight-bold">Original Payment Terms:&nbsp;</div>
        {{ formatPaymentTerms(trackingSummary.systemSelectedPaymentTerms) }}
      </span>
      <v-form ref="edit-payment-terms-form">
        <CRSelect
          id="new-payment-terms-reason-type-select"
          v-model="selectedReasonType"
          :items="reasonTypes"
          label="Reason Type"
          item-text="label"
          item-value="key"
          :rules="[(val) => !!val || 'Reason Type Required']"
        />
        <CRSelect
          id="new-payment-terms-select"
          v-model="selectedPaymentTerms"
          :items="paymentTermsOptions"
          label="New Payment Terms"
          item-text="label"
          item-value="key"
          :disabled="selectedReasonType === 'COMPLAINT_RESOLVED' || selectedReasonType === 'OPEN_COMPLAINT'"
          :rules="[(val) => !!val || 'New Payment Terms Required']"
        />
        <div v-if="upgradingPaymentTerms && selectedReasonType === 'INCORRECT_TRACKED_STATUS'" class="margin-b-6">
          <div class="text-gray">For this reason type, all vehicles must be tracked-on-time. Please review the statuses that will now be overridden as ‘qualified’.</div>
          <div v-for="(journeySummary, journeySummaryIndex) in untrackedJourneys" :key="vehicleIndex" class="margin-t-5">
            <p class="padding-a-0 margin-b-1 font-weight-bold text-gray">Vehicle: {{ vehicleName(journeySummary.vehicleId) }}</p>
            <v-layout row>
              <span v-for="(criteria, criteriaIndex) in unmetCriteria(journeySummary)" :key="criteriaIndex" style="display: inline-flex; align-items: center" class="margin-r-5">
                <CRIcon
                  color="errorNew"
                  view-box="-4 0 30 24"
                >
                  close
                </CRIcon>
                {{ criteria }}
              </span>
            </v-layout>
          </div>
        </div>
        <div v-else-if="downgradingPaymentTerms && selectedReasonType === 'INCORRECT_TRACKED_STATUS'" class="text-gray margin-b-6">
          Please record in detail why the tracked-on-time status was incorrect. Comments are required for this reason type.
        </div>
        <v-textarea
          v-if="selectedReasonType !== null"
          v-model="paymentTermsOverrideNotes"
          id="downgrade-payment-terms-text-area"
          placeholder="Record information about the new payment terms here."
          class="margin-t-3"
          flat
          solo
          no-resize
          :rules="[(val) => !!val || 'Comments Required']"
        />
      </v-form>
    </div>
    <v-btn
      :id="`edit-payment-terms-sidebar-save-button`"
      class="edit-payment-terms-sidebar--action-btn"
      color="success-new"
      :loading="submitting"
      @click="submit"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { overridePaymentTerms } from '@/services/reservations'

export default {
  props: {
    referralId: {
      type: Number,
      default: null
    },
    providerVehicles: {
      type: Array,
      default: () => []
    },
    trackingSummary: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedPaymentTerms: null,
      selectedReasonType: null,
      paymentTermsOverrideNotes: null,
      reasonTypes: [
        {
          key: 'INCORRECT_TRACKED_STATUS',
          label: 'Incorrect Tracked-On-Time Status'
        },
        {
          key: 'COMPLAINT_RESOLVED',
          label: 'Complaint Resolved'
        },
        {
          key: 'OTHER',
          label: 'Other'
        }
      ],
      submitting: false,
    }
  },
  computed: {
    currentPaymentTerms() {
      return this.trackingSummary?.paymentTerms
    },
    paymentTermsOptions() {
      const paymentTerms = [
        {
          key: 'net_14',
          label: 'Net-14 Terms'
        },
        {
          key: 'net_3',
          label: 'Net-3 Terms'
        },
        {
          key: 'pending',
          label: 'Pending'
        }
      ]
      if (this.selectedReasonType === 'COMPLAINT_RESOLVED') {
        return paymentTerms
      }
      return paymentTerms.filter((t) => t.key !== this.currentPaymentTerms)
    },
    upgradingPaymentTerms() {
      return this.currentPaymentTerms === 'net_14' && this.selectedPaymentTerms === 'net_3'
    },
    downgradingPaymentTerms() {
      return this.currentPaymentTerms === 'net_3' && this.selectedPaymentTerms === 'net_14'
    },
    untrackedJourneys() {
      return this.trackingSummary?.journeyTrackingSummary?.filter((s) => !(s.onTime && s.trackedArrival && s.trackedOverall))
    },
  },
  watch: {
    selectedReasonType(newValue, oldValue) {
      if (newValue === 'COMPLAINT_RESOLVED') {
        this.selectedPaymentTerms = this.trackingSummary.systemSelectedPaymentTerms
      } else if (newValue === 'OPEN_COMPLAINT') {
        this.selectedPaymentTerms = 'pending'
      } else if (oldValue === 'COMPLAINT_RESOLVED' && oldValue !== newValue) {
        this.selectedPaymentTerms = null
      }
    }
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    formatPaymentTerms(terms) {
      return terms
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join('-');
    },
    vehicleName(vehicleId) {
      return this.providerVehicles.find((v) => v.vehicleId === vehicleId)?.vehicleName
    },
    unmetCriteria(journeySummary) {
      const criteria = []
      if (!journeySummary.trackedArrival) {
        criteria.push('Tracked Arrival')
      }
      if (!journeySummary.onTime) {
        criteria.push('On-Time')
      }
      if (!journeySummary.trackedOverall) {
        criteria.push('GPS Data')
      }
      return criteria
    },
    async submit() {
      this.submitting = true
      const form = this.$refs['edit-payment-terms-form']

      if (!form.validate()) {
        this.submitting = false
        return
      }

      const payload = {
        referralId: this.referralId,
        overrideReasonType: this.selectedReasonType,
        newPaymentTerms: ['COMPLAINT_RESOLVED', 'OPEN_COMPLAINT'].includes(this.selectedReasonType) ? null : this.selectedPaymentTerms,
        overrideNotes: this.paymentTermsOverrideNotes
      }

      try {
        await overridePaymentTerms(payload)
        this.$store.dispatch('app/closeSidebarDialog')
        this.$nextTick(() => {
          this.showAlert({
            type: 'success',
            message: 'Payment terms successfully overridden.',
          })
        })
      } catch (e) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error overriding payment terms.',
        })
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-payment-terms-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--textbox {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &--back-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--btn-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--delete-btn {
    border: none;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    margin: 0;
    border-radius: 0px !important;
  }
}
</style>
